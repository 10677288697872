.languageSelector {
  padding-left: 10px;
}

div[role=menubar] button:disabled {
  opacity: 0.8;

  a {
    svg {
      margin-left: 11px;
      margin-right: 5px;
    }
    text-decoration: none;
    font-weight: inherit!important;
  }
}

.element {
  display: flex;
  align-items: center;

  .flag {
    height: 17px;
    width: 23px;
    margin: 0 5px;
    box-shadow: rgb(204, 204, 204) 1px 1px 3px;
  }
}
