.container {
  background-color: rgba(255, 255, 255, 0.5);
  p, ul {
    text-align: justify;
  }
  h3 {
    margin-bottom: 0;
    max-width: 630px;
  }
  ul {
    max-width: 760px;
  }
}
