.navbar {
  a {
    text-decoration: none;
  }

  transition: margin .2s ease;

  .logoContainer {
    overflow: hidden;
    position: absolute;
    .logo {
      transition: margin .6s ease;
      height: 40px;
    }
  }

  .logoHands {
    height: 40px;
    box-shadow: 0 0 0 1px #ffffff; // better hide background logo during shakes
    z-index: 1;
    pointer-events: none;
  }

  &:hover {
    margin-top: -5px;
    margin-bottom: 5px;

    .logo {
      margin-top: 7px;
      margin-bottom: -7px;

      animation: shake 0.4s;
      animation-iteration-count: infinite;
    }
  }
}

@keyframes shake {
  0% { transform: rotate(-2deg)}
  50% { transform: rotate(2deg) }
  100% { transform: rotate(-2deg)}
}