.pro {
  color: #b742a0;
}

.googlePayIcon {
  height: 26px;
  margin-right: 5px;
}

.checkoutCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
}

.checkoutButton {
  padding-left: 5px!important;
  margin-top: 15px!important;
}

.subscriptionLink {
  font-size: 15px;
}

.friendCodeLine {
  p{
    display: inline-block;
    margin-right: 10px;
  }
  div { // input container
    display: inline;
  }
  input {
    padding: 3px;
    width: 5em;
  }
}

.friendCodeLine.error {
  color: red;
  input {
    border-color: red;
  }
}